import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

//登陆河口

const TokenhekouKey = 'Admin-Token-Dushui'

export function gethekouToken() {
  return Cookies.get(TokenhekouKey)
}

export function sethekouToken(token) {
  return Cookies.set(TokenhekouKey, token)
}

export function removehekouToken() {
  return Cookies.remove(TokenhekouKey)
}

