<template>
  <div class="wrap">
    <div class="ScaleBox"
         style=""
         ref="ScaleBox"
         :class="showColorBG ? 'x-scale-box-bg' : ''"
         :style="{
          width : width +'px',
          height : height +'px',
        }">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "x-scale-box",
  props: {
    width: {
      type: Number,
      default: 1920
    },
    height: {
      type: Number,
      default: 1080
    },
    xScale: {
      type: Boolean,
      default: true,
    },
    yScale: {
      type: Boolean,
      default: true,
    },
    showColorBG: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      scale: null
    };
  },

  mounted() {
    this.setScale();
    window.addEventListener("resize", this.setScale);
  },

  methods: {

    getScale() {
      const {width, height} = this;
      let ww = window.innerWidth / width;
      let wh = window.innerHeight / height;
      return {ww, wh};
    },

    setScale() {
      let scaleNum = this.getScale();

      this.$refs.ScaleBox.style.setProperty("--scaleX", this.xScale ? scaleNum.ww : 1);

      this.$refs.ScaleBox.style.setProperty("--scaleY", this.yScale ? scaleNum.wh : 1);
    },

    debounce(fn, delay) {
      let delays = delay || 500;
      let timer;
      return function () {
        let th = this;
        let args = arguments;
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(function () {
          timer = null;
          fn.apply(th, args);
        }, delays);
      };
    }
  }
};
</script>

<style>

#ScaleBox {
  --scaleX: 1;
  --scaleY: 1;
}

.wrap {
  background-color: black;
  color: white;
}

.ScaleBox {
  position: absolute;
  transform: scale(var(--scaleX), var(--scaleY)) translate(-50%, -50%) translate3d(0, 0, 0);
  display: flex;
  flex-direction: column;
  transform-origin: 0 0;
  left: 50%;
  top: 50%;
  transition: 0s;
  z-index: 999;
  overflow: hidden;
  /*transform: scale(var(--scale));*/
  /*transform-origin: left top;*/
  /*background-color: black;*/
  /*background-size: 100%;*/
}


.x-scale-box-bg {
  background-color: #002753;
}
</style>
