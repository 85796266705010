<template>
  <div style="position: absolute;top: 80px;left:700px;z-index: 200" class="cursor text-white">
    <div class="flex" style="position: absolute;top: 4px;left:0" :class="checkId === 'zssn' ? '' : 'x-wei-xuan-zhong'"
         @click="menuClick('zssn')">
      <div>
        <video src="@/assets/video/大脑10s.webm" muted autoplay loop
               style="width: 129px"></video>
      </div>
      <div class="pangMenZhengDao" style="font-size: 34px;width: 180px;margin-top: 50px;margin-left: 0px">智水枢脑</div>
    </div>

    <div class="flex" style="position: absolute;top: 4px;left:350px"
         :class="checkId === 'zhyt' ? '' : 'x-wei-xuan-zhong'" @click="menuClick('zhyt')">
      <div>
        <video src="@/assets/video/地球10s.webm" muted autoplay loop
               style="width: 100px;margin-top: 15px"></video>
      </div>
      <div class="pangMenZhengDao" style="font-size: 34px;width: 180px;margin-top: 50px;margin-left: 10px">综合舆图</div>
    </div>

  </div>
</template>

<script>
export default {
  name: "x-header-webm",
  data: () => {
    return {
      checkId: '',
    }
  },
  props: [],
  created() {

  },
  mounted() {
    let name = this.$route.name;
    if(name === '综合舆图'){
      this.checkId = 'zhyt';
    }else if (name === '智水枢脑'){
      this.checkId = 'zssn';
    }else{
      this.checkId = '';
    }
  },
  beforeDestroy() {
  },
  methods: {

    menuClick(val) {
      this.checkId = val;
      switch (val) {
        case 'zssn':
          this.$router.push('/ShuNao')
          break

        case 'zhyt':
          this.$router.push('/zongHeYuTu')
          break
      }

    }

  }
}
</script>

<style scoped>

.x-wei-xuan-zhong {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray;
}

</style>
