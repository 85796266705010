<template>
  <div style="position: relative;">
    <video src="@/assets/video/顶部.webm" muted autoPlay loop
           style="width: 1920px;height: 200px;z-index: 111;position: absolute;top: 0;left:0"></video>
    <img src="@/assets/ShuNao/标题.png" style="width: 100%;height: 87px;position: absolute;top: 0;left:0;z-index: 1"
         :class="opacityBg ? 'x-opacity' : ''">
    <div style="width: 100%;text-align: center;position: absolute;top: 12px;left:0;z-index: 111">
      <img :src="titleBg || titleText"
           style="width: 552px;height: 69px;">
    </div>
  </div>
</template>

<script>
import titleText from '@/assets/ShuNao/titleText.png'

export default {
  name: "x-header-shuNao",
  props: ['titleBg', 'opacityBg'],
  data: () => {
    return {
      titleText
    }
  },
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>

<style scoped>

.x-opacity{
  opacity: 0.7
}

</style>
