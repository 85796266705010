import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'home',
		component: () => import('../views/login/index.vue')
	},
	{
		path: '/index1',
		name: '河畅水美护生态',
		component: () => import('../views/HeChangShuMei/taiShiGanZhi/index.vue')
	},
	{
		path: '/index2',
		name: '河畅水美护生态',
		component: () => import('../views/HeChangShuMei/taiShiGanZhi/index.vue')
	},
	{
		path: '/index',
		name: '河畅水美护生态',
		component: () => import('../views/HeChangShuMei/taiShiGanZhi/index.vue')
	},
	{
		path: '/heDaoYuTu',
		name: '河道舆图',
		component: () => import('../views/HeChangShuMei/heDaoYuTu/index.vue')
	}, {
		path: '/diaoDuGuanLi',
		name: '调度管理',
		component: () => import('../views/HeChangShuMei/diaoDuGuanLi/index.vue')
	}, {
		path: '/heHuWenHua',
		name: '河湖文化',
		component: () => import('../views/HeChangShuMei/heHuWenHua/index.vue')
	},


	{
		path: '/ShuNao',
		name: '智水枢脑',
		component: () => import('../views/ShuNao/index.vue')
	}, {
		path: '/login',
		name: 'home',
		component: () => import('../views/login/index.vue')
	}, {
		path: '/YiZhangTu',
		name: '旧版本一张图',
		component: () => import('../views/YiZhangTu/index.vue')
	}, {
		path: '/ShuiZiYuan',
		name: '水资源',
		component: () => import('../views/ShuiZiYuan/index.vue')
	}, {
		path: '/ZaiJianGongCheng',
		name: '在建工程',
		component: () => import('../views/ZaiJianGongCheng/index.vue')
	}, {
		path: '/YongZhenShuiKu',
		name: '永镇水库',
		component: () => import('../views/YongZhenShuiKu/index.vue')
	}, {
		path: '/ShuiXiLianTong',
		name: '水系连通',
		component: () => import('../views/ShuiXiLianTong/index.vue')
	}, {
		path: '/zongHeYuTu',
		name: '综合舆图',
		component: () => import('../views/YiZhangTu2/zongHeYuTu/index.vue')
	}, {
		path: '/SheBeiShiShiShuJu',
		name: '设备实时数据',
		component: () => import('../views/SheBeiDialog/ShiShiShuJu.vue')
	}, {
		path: '/duoWeiYuTu',
		name: '多维舆图',
		component: () => import('../views/YiZhangTu2/duoWeiYuTu/index.vue')
	}, {
		path: '/zongHeYuTuHome',
		name: '多维舆图Home',
		component: () => import('../views/YiZhangTu2/zongHeYuTuHome/index.vue')
	}, {
		path: '/shuiKuYuTu',
		name: '水库舆图',
		component: () => import('../views/YiZhangTu2/shuiKuYuTu/index.vue')
	}, {
		path: '/shuiKuYuTuDetai',
		name: '水库舆图详情',
		component: () => import('../views/YiZhangTu2/shuiKuYuTuDetai/index.vue')
	}, {
		path: '/heDaoYuTu',
		name: '河道舆图',
		component: () => import('../views/YiZhangTu2/heDaoYuTu/index.vue')
	}, {
		path: '/heDaoYuTuDetai',
		name: '河道舆图详情',
		component: () => import('../views/YiZhangTu2/heDaoYuTuDetai/index.vue')
	}, {
		path: '/guanQuYuTu',
		name: '灌区舆图',
		component: () => import('../views/YiZhangTu2/guanQuYuTu/index.vue')
	}, {
		path: '/guanQuYuTuDetai',
		name: '灌区舆图详情',
		component: () => import('../views/YiZhangTu2/guanQuYuTuDetai/index.vue')
	}, {
		path: '/bengZhanYuTu',
		name: '泵站舆图',
		component: () => import('../views/YiZhangTu2/bengZhanYuTu/index.vue')
	}, {
		path: '/bengZhanYuTuDetai',
		name: '泵站舆图详情',
		component: () => import('../views/YiZhangTu2/bengZhanYuTuDetai/index.vue')
	}, {
		path: '/shuiZhaYuTu',
		name: '水闸舆图',
		component: () => import('../views/YiZhangTu2/shuiZhaYuTu/index.vue')
	}, {
		path: '/shuiZhaYuTuDetai',
		name: '水闸舆图详情',
		component: () => import('../views/YiZhangTu2/shuiZhaYuTuDetai/index.vue')
	}, {
		path: '/sheBeiYuTu',
		name: '设备舆图',
		component: () => import('../views/YiZhangTu2/sheBeiYuTu/index.vue')
	}, {
		path: '/shuiWenYuTu',
		name: '水文舆图',
		component: () => import('../views/YiZhangTu2/shuiWenYuTu/index.vue')
	}, {
		path: '/zuoZhanYuTu',
		name: '作战舆图',
		component: () => import('../views/YiZhangTu2/zuoZhanYuTu/index.vue')
	}


];

const router = new VueRouter({
	routes
})

export default router