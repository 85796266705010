class XUtil {
    constructor() {
    }

    /**
    * @Description: 根据传入的val判断返回文字闪烁效果 【gj、yu】
    * @Author: LiYang
    * @Date:  2022/7/26  0:07
    */
    getTextAnimation(val) {
        switch (val) {
            case 'gj':
                return 'x-text-shanShuo-gaoJing';
            case 'yj':
                return 'x-text-shanShuo-yuJing';
        }

    }


}

export default new XUtil();
