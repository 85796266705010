<template>
  <div class="x-title-bg-2">
    <div class="x-title-text">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: "index",
  data: () => {
    return {}
  },
  props: ['title'],
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>

<style scoped>
.x-title-bg-2 {
  width: 453px;
  height: 47px;
  background: url("~@/assets/YiZahngTu2/小标题.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
}

.x-title-text {
  font-size: 20px;
  font-family: douyuFont-2;
  position: absolute;
  left: 50px;
  top: 12px;
  letter-spacing: 2px;
  color: white;
}
</style>
